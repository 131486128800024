import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { getQueryString } from '../components/getQueryString';
import Arrow from '../images/arrow-right.svg';

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  margin-bottom: 50px;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const SearchPageTitle = styled.h1`
  color: var(--white);
  font-size: 1.75rem;
  margin: 40px 0;
`;

const SearchNoResults = styled.p`
  color: var(--white);
`;

const SearchResult = styled.div`
  margin: 0 0 40px;

  @media (min-width: 64em) {
    display: flex;
  }
`;

const SearchResultImageWrapper = styled(Link)`
  @media (min-width: 64em) {
    max-width: 270px;
    margin-right: 20px;
  }

  img {
    width: 100%;
  }
`;

const SearchResultTitle = styled(Link)`
  color: var(--white);
  display: block;
  font-size: 1.375rem;
  margin: 8px 0;

  @media (min-width: 64em) {
    margin: 0 0 8px;
  }
`;

const SearchResultDescription = styled.div`
  color: var(--white);
  font-size: 1.125rem;
  margin: 0 0 10px;
`;

const SearchResultLink = styled(Link)`
  color: var(--white);

  &:hover,
  &:focus {
    & > svg {
      fill: var(--blue);
      transition: fill .5s ease;
    }
  }
`;

const ArrowRight = styled(Arrow)`
  height: 10px;
  fill: var(--white);
  margin-left: 8px;
`;

const mainInputSearch = () => {
  const data = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `);

  const { index, store } = data.localSearchPages;
  const queryString = getQueryString('query');
  const pagesResults = useFlexSearch(
    queryString,
    index,
    JSON.parse(store),
  );

  const handleClickThrough = (type, slug) => {
    let stringStart = 'projects';

    if (type === 'DatoCmsNews') {
      stringStart = 'news';
    }

    return `/${stringStart}/${slug}`;
  };

  return (
    <Layout>
      <SEO title="Search" />
      <StyledGrid fluid>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <SearchPageTitle>search results</SearchPageTitle>
            {Object.keys(pagesResults).length === 0
              && <SearchNoResults>Sorry, no results found</SearchNoResults>}
          </Col>
        </Row>
        <Row>
          {Object.keys(pagesResults).length > 0
            && pagesResults.map((result) => (
              <Col lg={6} md={12} sm={12} xs={12} key={result.id}>
                <SearchResult>
                  <SearchResultImageWrapper to={handleClickThrough(result.type, result.slug)}>
                    <img src={result.image} alt="" />
                  </SearchResultImageWrapper>
                  <div className="text-wrapper">
                    <SearchResultTitle to={handleClickThrough(result.type, result.slug)}>
                      {`${result.title.substring(0, 55)}...`}
                    </SearchResultTitle>
                    <SearchResultDescription>
                      {`${result.description.substring(0, 80)}...`}
                    </SearchResultDescription>
                    <SearchResultLink to={handleClickThrough(result.type, result.slug)}>
                      Read More
                      <ArrowRight />
                    </SearchResultLink>
                  </div>
                </SearchResult>
              </Col>
            ))}
        </Row>
      </StyledGrid>
    </Layout>
  );
};

export default mainInputSearch;
